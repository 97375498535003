// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tEp7pLmLnDArtZro2uHk{background-color:#f2f2f0;border-radius:8px;border:1px solid #e6e6e6;padding:24px 24px 16px 24px;display:flex;flex-direction:column;gap:8px}.tEp7pLmLnDArtZro2uHk>h5{margin-bottom:8px}.tEp7pLmLnDArtZro2uHk>div{display:flex;justify-content:space-between}.tEp7pLmLnDArtZro2uHk>hr,.tEp7pLmLnDArtZro2uHk>button,.tEp7pLmLnDArtZro2uHk>span{margin:8px 0}.tEp7pLmLnDArtZro2uHk>button{display:flex;justify-content:center}.tEp7pLmLnDArtZro2uHk>span{text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "tEp7pLmLnDArtZro2uHk"
};
export default ___CSS_LOADER_EXPORT___;
