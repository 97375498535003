// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bXA9IQVpm1J7RC1XNxgm{background-color:gray}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"voucher": "bXA9IQVpm1J7RC1XNxgm"
};
export default ___CSS_LOADER_EXPORT___;
