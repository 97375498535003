// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hOpTQB2naILpMxZTEZOT>textarea{resize:none;width:100%;border-radius:4px;border:1px solid #e6e6e6;padding:12px;margin-top:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"note": "hOpTQB2naILpMxZTEZOT"
};
export default ___CSS_LOADER_EXPORT___;
