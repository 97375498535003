import cn from 'classnames';
import styles from './Inputs.module.scss';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useState } from 'react';

type InputPhoneProps = {
    name: string;
    label: string;
    value: string | undefined;
    onChange: (value: string) => void;
    onBlur: (ev: React.FocusEvent<HTMLInputElement, Element>) => void;
    error?: string | undefined;
    disabled?: boolean;
    readonly?: boolean;
    children?: React.ReactNode;
    className?: string | undefined;
}

export const InputPhone = ({ name, label, value, disabled = false, readonly = false, onChange, onBlur, children, error, className }: InputPhoneProps) => {
    const [internalValue, setInternalValue] = useState(value);

    return (<div className="form-text">
        <PhoneInput
            placeholder={label}
            value={value}
            onChange={(v) => { onChange(v ?? ""); setInternalValue(v ?? ""); }}
            onBlur={onBlur}
            className={className}
            readOnly={readonly}
            disabled={disabled} />
        <label htmlFor={name} className={cn(internalValue ? styles.onTop : styles.hidden)}>
            <span>{label}</span>
        </label>
        {children && <div className={styles.suffix}>{children}</div>}
        {error && <span className="form-error">{error}</span>}
    </div>);
}